
import React from "react";
import './Footer.css';

export const Footer = (props) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <ul className="list-unstyled list-inline social text-center">
            <li className="list-inline-item">
              <a href="https://twitter.com/HudsonHSLax" className="nav-link" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.instagram.com/hudsonlacrosse/" className="nav-link" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <ul className="list-unstyled list-inline quick-links text-center">
            <li className="list-inline-item"><a href="https://www.hudson.k12.oh.us/Domain/13" target="_blank" rel="noopener noreferrer">Hudson High School</a></li>
            <li className="list-inline-item text-white"> | </li>
            <li className="list-inline-item"><a href="https://hudsonexplorersathletics.com/" target="_blank" rel="noopener noreferrer">Hudson Athletics</a></li>
            <li className="list-inline-item text-white"> | </li>
            <li className="list-inline-item"><a href="http://www.hudsonlax.com/" target="_blank" rel="noopener noreferrer">Hudson Youth Lacrosse</a></li>
          </ul>
        </div>
        <hr />
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 text-center text-white">
          <p className="sc">2011 Ohio Division 1 State Champions</p>
          <p className="very-small">For up-to-the-minute game status information, call the Hudson High School Sports Hotline at 330-655-6010.
          <br />&copy; All Rights Reserved. Hudson Explorers Lacrosse</p>
        </div>
        <hr />
      </div>	
    </div>
  )
};
