import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import './forms.css'

export const ParentInfoForm = (props) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter your name"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
  });

  return (
    <Formik
      initialValues={{ name: '', email: '' }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        await props.onSubmit(values.name, values.email);
        setSubmitting(false);
      }}
    >
      {({ values, touched, errors, isSubmitting, handleChange, handleSubmit }) => {
      return (
        <form onSubmit={handleSubmit}>
          <div className="formcontainer">
            <div className="fieldGroup">
              <label htmlFor="name">Enter your name:</label>
              <input
                name="name"
                onChange={handleChange}
                value={values.name}
                className={errors.name && touched.name && 'fld-err'}
              />
              {errors.name && touched.name && (
                <div className='err-msg'>{errors.name}</div>
              )}
            </div>
            <div className="fieldGroup">
              <label htmlFor="name">Enter your email:</label>
              <input
                name="email"
                onChange={handleChange}
                value={values.email}
                className={errors.email && touched.email && 'fld-err'}
              />
              {errors.email && touched.email && (
                <div className='err-msg'>{errors.email}</div>
              )}
            </div>
            <div className="fieldGroup">
              <button type="submit" disabled={isSubmitting}>{props.submitText || 'Submit'}</button>
            </div>
          </div>
          {props.children}
        </form>
      );
      }}
    </Formik>);
};

export default ParentInfoForm;