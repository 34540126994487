import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import './Navigation.css';

export const Navigation = (props) => {
  return (
    <Navbar expand='sm' variant='dark'>
      <NavLink className="headerLogoContainer" to="/">
        <img className='headerLogo' src="/img/hhs_lax_logo_2020_64.png" alt="Hudson Explorers" />
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <Nav>
        <Nav.Item>
          <NavLink activeClassName='active' className="nav-link" to="/schedule">Schedule</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink activeClassName='active' className="nav-link" to="/roster">Roster</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink activeClassName='active' className="nav-link" to="/staff">Staff</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink activeClassName='active' className="nav-link" to="/contribute">Contribute</NavLink>
        </Nav.Item>
        <NavDropdown title="Alumni" id="nav-dropdown">
          <NavDropdown.Item>
            <NavLink activeClassName='active' className="dropnav-link" to="/alumni">Alumni Directory</NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <NavLink activeClassName='active' className="dropnav-link" to="/alumni-registry">Alumni Registry</NavLink>
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Coach's Corner" id="nav-dropdown">
          <NavDropdown.Item href="https://hudsonlacrosse.smugmug.com/" target="_blank" rel="noopener noreferrer">
            Pictures
          </NavDropdown.Item>
          <NavDropdown.Item href="docs/Hudson Lacrosse Wall Ball Routine.pdf" target="_blank" rel="noopener noreferrer">
            Wall Ball Routine
          </NavDropdown.Item>
          {/* <NavLink activeClassName='active' className="dropdown-item" to="/parents">Parent Information</NavLink>
          <NavLink activeClassName='active' className="dropdown-item" to="/practices">Practice Schedule</NavLink> */}
        </NavDropdown>
      </Nav>
      <Nav className="navbar-social">
        <Nav.Item>
          <a href="https://twitter.com/HudsonHSLax" className="nav-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </Nav.Item>
        <Nav.Item>
          <a href="https://www.instagram.com/hudsonlacrosse/" className="nav-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </Nav.Item>
      </Nav>
      </Navbar.Collapse>
      <ul className="nav justify-content-end">
        <li>
          <img className='header-logo-right' src="/img/logo_white.png" alt="Hudson Explorers Lacrosse" /> 
        </li>
      </ul>
    </Navbar>
  )
};
