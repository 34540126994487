
import React from "react";
import './Banner.css';

export const Banner = (props) => {
  return (
    <div className="container-fluid banner d-flex">
      <div className="container-fluid hero-title-bg">
        <div className="container hero-title">
          <h1 className="display-4 align-self-end align-items-end">{props.BannerText}</h1>
        </div>
      </div>
    </div>
  )
};
