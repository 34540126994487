import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import './Loader.css';

export const Loader = () => {
  return (
    <div className='spinnerContainer'>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )
};