import React from "react";
import PropTypes from "prop-types";
import { Banner } from '../Banner/Banner'
import { SeasonNav } from '../SeasonNav/SeasonNav';
import { Loader } from '../Loader/Loader';
import { API_BASE_URL } from '../../utils/config';
import axios from 'axios';
import { withRouter } from "react-router";

const availableRosters = [
  { szn: '2023', sheet: 'Roster-2023'},
  { szn: '2022', sheet: 'Roster-2022'},
  { szn: '2021', sheet: 'Roster-2021'},
  { szn: '2020', sheet: 'Roster-2020'},
  { szn: '2019', sheet: 'Roster-2019'},
  ];

class Roster extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { szn: '2023', roster: { all: [], varsity: [], jv: [] }, loading: true, error: false }
  }

  componentWillMount() {
    const { match: { params: { szn: sznParam } } } = this.props;
    this.loadRoster(sznParam);
  }

  componentDidUpdate() {
    const { match: { params: { szn: sznParam } } } = this.props;
    const { szn, loading } = this.state;

    if (szn !== sznParam) {
      this.setState({ szn: sznParam, loading: true });
    }
    if (loading) {
      this.loadRoster(sznParam);
    }
  }

  async loadRoster(szn) {
    const { history } = this.props;
    const sznRoster = availableRosters.find(x => x.szn === szn);
    if (!sznRoster) {
      history.replace('/notfound');
    }
    try {
      const route = `${API_BASE_URL}/api/roster/${sznRoster.sheet}`;
      const rosterResponse = await axios.get(route);
      const all =  rosterResponse.data.filter(x => x.Team === 'tbd');
      const varsity =  rosterResponse.data.filter(x => x.Team === 'varsity');
      const jv =  rosterResponse.data.filter(x => x.Team === 'jv');
      this.setState({ szn, roster: { all, varsity, jv }, loading: false });
    } catch (err) {
      this.setState({ szn, error: true, loading: false });
    }
  }

  renderRosterItem = (item, index, teamKey) => {
    if (item.Captain === '1') {
      item.Suffix = '*';
    }
    // } else if (item.Flow === '1') {
    //   item.Suffix = '**';
    // }
    return (
    <tr key={`rosterItem-${teamKey}-${index}`}>
      <td scope="row">{item.Number}</td>
      <td>{item.First}</td>
      <td>{`${item.Last}${item.Suffix || ''}`}</td>
      <td>{item.Class}</td>
      <td>{item.Position}</td>
    </tr>
    );
  }

  renderTeamRoster(teamObj, teamKey) {
    return (
    <div className="tableWrapper">
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Class</th>
            <th scope="col">Position</th>
          </tr>
        </thead>
        <tbody>
          {
            teamObj.map((item, index) => this.renderRosterItem(item, index, teamKey))
          }
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { szn, roster: { all, varsity, jv }, loading } = this.state;
    return (
      <div className="bodyContainer">
        <Banner BannerText={`${szn} Roster`} />
        <SeasonNav baseRoute="/roster" seasons={availableRosters} />
        <div className="container">
          <div className="row">
            { loading && 
            <Loader />
            }
            {!loading && all.length > 0 && varsity.length === 0 && jv.length === 0 && <div className="col-lg-12 col-md-12 mb-4">
              {this.renderTeamRoster(all, 'all')}
              { szn > 2018 && <div>* captain</div> }
            </div>}
            {!loading && varsity.length > 0 && <div className="col-lg-6 col-md-12 mb-4">
              <h2>Varsity</h2>
              {this.renderTeamRoster(varsity, 'varsity')}
              { szn > 2018 && <div>* captain</div> }
              {/* { szn > 2019 && <div>** all flow team</div> } */}
            </div>}
            {!loading && jv.length > 0 && <div className="col-lg-6 col-md-12">
              <h2>JV</h2>
              {this.renderTeamRoster(jv, 'jv')}
              { szn > 2018 && <div>* captain</div> }
              {/* { szn > 2019 && <div>** all flow team</div> } */}
            </div>}
          </div>
        </div>
      </div>
    )
  };
}

export default withRouter(Roster);
