import React from "react";
import './NotFound.css'

export const NotFound = (props) => {
  return (
    <div className="bodyContainer not-found">
      <div className="container-fluid d-flex justify-content-center align-items-center not-found-bg">
        <div className="container text-center">
          <h1 className="cover-heading">UH OH, Empty Net!</h1>
          <p className="lead">It seems the page you're looking for does not exist.<br />You can try again, but it still won't be there.</p>
        </div>
      </div>
    </div>
  )
};