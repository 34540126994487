import _ from 'lodash';
import React from 'react';
import PropTypes from "prop-types";
import { Banner } from '../Banner/Banner';
import { Loader } from '../Loader/Loader';
import { SeasonNav } from '../SeasonNav/SeasonNav';
import { API_BASE_URL } from '../../utils/config';
import axios from 'axios';
import { withRouter } from "react-router";
import './Schedule.css'

const initScheduleObj = {
  varsity: {
    wins: 0,
    losses: 0,
    ties: 0,
    items: []
  },
  varsityBlue: {
    wins: 0,
    losses: 0,
    ties: 0,
    items: []
  },
  jv: {
    wins: 0,
    losses: 0,
    ties: 0,
    items: []
  }
}

const availableSchedules = [
  { szn: '2023', sheet: 'Schedule-2023'},
  { szn: '2022', sheet: 'Schedule-2022'},
  { szn: '2021', sheet: 'Schedule-2021'},
  { szn: '2020', sheet: 'Schedule-2020'},
  { szn: '2019', sheet: 'Schedule-2019'},
  ]

class Schedule extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { szn: '2023', schedule: _.cloneDeep(initScheduleObj), loading: true }
  }

  componentWillMount() {
    const { match: { params: { szn: sznParam } } } = this.props;
    this.loadSzn(sznParam);
  }

  componentDidUpdate() {
    const { match: { params: { szn: sznParam } } } = this.props;
    const { szn, loading } = this.state;

    if (szn !== sznParam) {
      this.setState({ szn: sznParam, loading: true })
    }
    if (loading) {
      this.loadSzn(sznParam);
    }
  }

  getTeamMap() {
    const teamMap = new Map()
    teamMap.set('Varsity', 'varsity')
    teamMap.set('Varsity Blue', 'varsityBlue')
    teamMap.set('JV', 'jv')
    return teamMap
  }

  async loadSzn(szn) {
    const { history } = this.props;
    const sznSchedule = availableSchedules.find(x => x.szn === szn);
    if (!sznSchedule) {
      history.replace('/notfound');
    }
    
    const route = `${API_BASE_URL}/api/schedule/${sznSchedule.sheet}`;
    const scheduleResponse = await axios.get(route);
    const teamMap = this.getTeamMap();

    const schedule = scheduleResponse && scheduleResponse.data && scheduleResponse.data.reduce((redObj, item) => {
      // const team = (item.Team === 'Varsity') ? redObj.varsity : redObj.jv;
      const teamProp = teamMap.get(item.Team)
      const team = teamProp && redObj[teamProp]
      if (team) {
        if (item.Result === 'W') {
          team.wins = team.wins + 1;
        } else if (item.Result === 'L') {
          team.losses = team.losses + 1;
        } else if (item.Result === 'T') {
          team.ties = team.ties + 1;
        }
        if (item.League === 'MSLCA') {
          item.suffix = '*';
        } else if (item.League === 'Suburban') {
          item.suffix = '**';
        }
        team.items.push(item);
      }
      return redObj;
    }, _.cloneDeep(initScheduleObj));
    this.setState({ szn, schedule, loading: false })
  }

  renderTeamSchedule(teamName, teamObj, szn) {
    const title = teamObj.ties > 0 ?
      `${teamName} ${teamObj.wins}-${teamObj.losses}-${teamObj.ties}` :
      `${teamName} ${teamObj.wins}-${teamObj.losses}`;
    const rowClass = teamObj.Classification === 'Home' ? 'home-game' : '';
    return (
    <div className="col-lg-12 col-md-12 mb-5">
      <h2>{title}</h2>
      <div className="tableWrapper">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr className={rowClass}>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
              <th scope="col">Opponent</th>
              <th scope="col">Location</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
            {
              teamObj.items.map((item, index) => (
                <tr key={`scheduleItem-${teamName}-${index}`}>
                  <td className="nowrap">{item.Date}</td>
                  <td className="nowrap">{item.Time}</td>
                  <td className="nowrap">{`${item.Opponent} ${item.suffix || ''}`}</td>
                  <td className="nowrap">
                    {item.LocationUrl ?
                      <a target="_blank" href={item.LocationUrl}>{item.Location}</a> :
                      (item.Location)
                    }
                  </td>
                  <td className="nowrap">
                    {
                      (item.Result) ?
                      `${item.Result} ${item['Hudson Score']}-${item['Opponent Score']} ${item.Qualifier || ''}` :
                      ''
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
        {szn === '2023' && teamName === 'Varsity Blue' &&
          (
          <div className="footnote">
            <div>** Suburban League</div>
          </div>
          )
        }
    </div>
    )
  }

  render() {
    const { szn, schedule: { varsity, varsityBlue, jv }, loading } = this.state;
    return (
      <div className="bodyContainer">
        <Banner BannerText={`${szn} SZN`} />
        <SeasonNav baseRoute="/schedule" seasons={availableSchedules} />
        <div className="container">
          <div className="row">
            { loading && 
            <Loader />
            }
            { !loading && this.renderTeamSchedule('Varsity', varsity, szn) }
            { !loading && varsityBlue.items.length > 0 && this.renderTeamSchedule('Varsity Blue', varsityBlue, szn) }
            { !loading && this.renderTeamSchedule('JV', jv) }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Schedule);