import React, { useState } from "react";
import { Banner } from '../Banner/Banner';
import { Loader } from '../Loader/Loader';
import { API_BASE_URL } from '../../utils/config';
import axios from 'axios';
import _clone from 'lodash/clone';
import './AlumniForm.css'

export const AlumniForm = (props) => {
  const [formValues, setFormValues] = useState({ name: '', email: '', phone: '', hsGradClass: '', postGrad: '', formErrors: {}, formValid: false});
  const [form2Values, setForm2Values] = useState({ email: '', formErrors: {}, formValid: false});
  const [result1, setResult1] = useState({ submitted: false });
  const [result2, setResult2] = useState({ submitted: false });
  const [loading, setLoading] = useState(false);

  const handleForm1Submit = () => {
    setLoading(true);
    const route = `${API_BASE_URL}/api/alumni`;
    const { name, email, phone, hsGradClass, postGrad } = formValues;
    const body = {
      name,
      email,
      cell: phone,
      year: hsGradClass,
      clout: postGrad
    };
    console.log('body ', body);
    const res = axios.post(route, body)
      .then((val) => {
        setResult1({ submitted: true, success: true, msg: val });
      })
      .catch((err) => {
        setResult1({ submitted: true, error: true, msg: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
    return false;
  }

  const handleForm2Submit = () => {
    setLoading(true);
    const { email } = form2Values;
    const route = `${API_BASE_URL}/api/alumni/${email}`;
    const res = axios.get(route)
      .then((val) => {
        setResult2({ submitted: true, success: true, msg: val === true ? 'Good news, we got you.' : 'Nope, nada ... hit the other form to give us your info.' });
      })
      .catch((err) => {
        setResult2({ submitted: true, error: true, msg: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
    return false;
  }
  
  const handleForm1Input  = (e) => {
    handleFormInput(e, formValues, setFormValues)
  }

  const handleForm2Input  = (e) => {
    handleFormInput(e, form2Values, setForm2Values)
  }

  const handleFormInput  = (e, values, set) => {
    const prop = e.target.name;
    const value = e.target.value;
    const newValues = _clone(values);
    newValues[prop] = value;
    newValues.formErrors = validateField(newValues.formErrors, prop, value);
    newValues.formValid = Object.keys(newValues.formErrors).length === 0;
    set(newValues);
  }

  const validateField = (formErrors, fieldName, value) => {
    const currentFieldError = formErrors[fieldName];
    let reg;
    let fieldError;
    let valid = true;
    switch(fieldName) {
      case 'name':
        valid = value.length > 2;
        fieldError = valid ? null : 'must enter a name';
        break;
      case 'email':
        reg = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        valid = reg.test(value);
        fieldError = valid ? null : 'email is invalid';
        break;
      case 'email2':
        reg = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        valid = reg.test(value);
        fieldError = valid ? null : 'email is invalid';
        break;
      // case 'phone':
      //   reg = new RegExp(/^(?:\d{1}\s)?\(?(\d{3})\)?-?\.?\s?(\d{3})-?\.?\s?(\d{4})$/i)
      //   valid = value.length === 0 || reg.test(value);
      //   fieldError = valid ? null : 'must be a phone number format ex: 330-555-1212';
      //   break;
      case 'hsGradClass':
        reg = new RegExp(/^\d{4}$/g)
        valid = value.match(/^\d{4}$/i);
        fieldError = valid ? null : 'enter your 4 digit hs graduation year';
        break;
      default:
        break;
    }
    if (!valid) {
      formErrors[fieldName] = fieldError;
    } else if (valid && currentFieldError) {
      delete formErrors[fieldName];
    }
    return formErrors;
  }

  const displayFieldError = (values, prop) => {
    const { formErrors } = values;
    if (formErrors && formErrors[prop]) {
      return (<div className="fld-err">{formErrors[prop]}</div>)
    }
  }
  
  return (
    <div className="bodyContainer">
      <Banner BannerText="Hudson Alumni Registry" />
      <div className="container alumni">
        { loading && 
          <Loader />
        }
        {!loading &&
          <div className="row">
            <div className="col-lg-6 col-md-12 mb-4">
              <form className="demoForm">
                <h4>Submit Alumni Info</h4>
                { result1.submitted && result1.error &&
                  <div className="result">Something went wrong. It's us, not you. Hit the wall and try again later.</div>
                }
                { result1.submitted && result1.success &&
                  <div className="result">Boom, we got you.</div>
                }
                { !result1.submitted &&
                <>
                  <div className="mb-4">Are you a Hudson Lacrosse alumni?<br />Give us your info so we can let you know about alumni events.</div>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    {displayFieldError(formValues, 'name')}
                    <input type="text" required className="form-control" name="name"
                      placeholder="your name"
                      value={formValues.name}
                      onChange={handleForm1Input}  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    {displayFieldError(formValues, 'email')}
                    <input type="email" required className="form-control" name="email"
                      placeholder="your email address"
                      value={formValues.email}
                      onChange={handleForm1Input}  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Cell</label>
                    {displayFieldError(formValues, 'phone')}
                    <input type="text" className="form-control" name="phone"
                      placeholder="nnn-nnn-nnnn (optional)"
                      value={formValues.phone}
                      onChange={handleForm1Input}  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="hsGradClass">HS Grad Year</label>
                    {displayFieldError(formValues, 'hsGradClass')}
                    <input type="text" className="form-control" name="hsGradClass"
                      placeholder="your 4 digit hs grad year"
                      value={formValues.hsGradClass}
                      onChange={handleForm1Input}  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postGrad">Where did you play or coach after HS?</label>
                    <input type="text" className="form-control" name="postGrad"
                      placeholder=""
                      value={formValues.postGrad}
                      onChange={handleForm1Input}  />
                  </div>
                  <button type="button" className="btn btn-primary" onClick={handleForm1Submit} disabled={!formValues.formValid}>
                    Submit Info
                  </button>
                </>
                }
              </form>
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <form className="lookupForm">
                <h4>Email Lookup</h4>
                { result2.submitted &&
                  <div className="result">{ result2.msg }</div>
                }
                { !result2.submitted &&
                <>
                  <div className="mb-4">Not sure if you already filled this out?<br />Enter your email addr here and we'll let you know.</div>
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    {displayFieldError(form2Values, 'email')}
                    <input type="email" required className="form-control" name="email"
                      placeholder="your email address"
                      value={form2Values.email}
                      onChange={handleForm2Input}  />
                  </div>
                  <button type="button" className="btn btn-primary" onClick={handleForm2Submit} disabled={!form2Values.formValid}>
                      Check Email
                  </button>
                </>
                }
              </form>
            </div>
          </div>
        }
      </div>
    </div>
  )
};
