import React from "react";
import { Banner } from '../Banner/Banner'
import './Alumni.css'

export const Alumni = (props) => {
  return (
    <div className="bodyContainer">
      <Banner BannerText="Hudson Alumni" />
      <div className="container alumni">
        <div className="row">
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
            <img alt="Cleveland State University" src="/img/alumni/clevelandstate.gif" />
            <p><span className="bigger-text">Matt Mehelic '21</span>
            <span className="small"><br/>Cleveland State
            <br />Defense</span></p>
          </div>
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
            <img alt="Mercyhurst University" src="/img/alumni/mercyhurst.gif" />
            <p><span className="bigger-text">Adam Oscarson '21</span>
            <span className="small"><br/>Mercyhurst
            <br />Midfield</span></p>
          </div>
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
            <img alt="Mercyhurst University" src="/img/alumni/mercyhurst.gif" />
            <p><span className="bigger-text">Chase Weber '21</span>
            <span className="small"><br/>Mercyhurst
            <br />Midfield</span></p>
          </div>
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
            <img alt="Walsh University" src="/img/alumni/walsh.png" />
            <p><span className="bigger-text">Alex Movshin '21</span>
            <span className="small"><br/>Walsh
            <br />Goalie</span></p>
          </div>
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
            <img className='alumni-img-rmu' alt="Robert Morris University" src="/img/alumni/Robert_Morris_University_logo.png" />
            <p><span className="bigger-text">Brady Ludewig '19</span>
            <span className="small"><br/>Robert Morris
            <br />Midfield</span></p>
          </div>
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img className='alumni-img-rmu' alt="Robert Morris University" src="/img/alumni/Robert_Morris_University_logo.png" />
              <p><span className="bigger-text">Blake Lori '19</span>
              <span className="small"><br/>Robert Morris
              <br />Attack</span></p>
            </div>
          <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Dickenson College" src="/img/alumni/dickinson.gif" />
              <p><span className="bigger-text">Stroud Corr '17</span>
              <span className="small"><br/>Dickinson College
              <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Hanover College" src="/img/alumni/hanover.gif" />
              <p><span className="bigger-text">Max Kleiman '17</span>
              <span className="small"><br/>	Hanover College
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Denison University" src="/img/alumni/denison.gif" />
              <p><span className="bigger-text">Enrique Rodriguez '17</span>
              <span className="small"><br/>	Denison University
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Cleveland State" src="/img/alumni/clevelandstate.gif" />
              <p><span className="bigger-text">Nate Menendez '16</span>
              <span className="small"><br/>	Cleveland State University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Mount Union" src="/img/alumni/mountunion.gif" />
              <p><span className="bigger-text">Chris Motter '16</span>
              <span className="small"><br/>	University of Mount Union
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Mount Union" src="/img/alumni/mountunion.gif" />
              <p><span className="bigger-text">Sean O'Loughlin '16</span>
                <span className="small"><br/>	University of Mount Union
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Mount Union" src="/img/alumni/mountunion.gif" />
              <p><span className="bigger-text">Grant Elffers '15</span>
              <span className="small"><br/>	University of Mount Union
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Mount Union" src="/img/alumni/mountunion.gif" />
              <p><span className="bigger-text">Jake Krone '15</span>
              <span className="small"><br/>	University of Mount Union
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Salisbury State" src="/img/alumni/salisbury.gif" />
              <p><span className="bigger-text">Trent Maendler '15</span>
              <span className="small"><br/>	Salisbury State University
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Southwestern University" src="/img/alumni/southwestern.gif" />
              <p><span className="bigger-text">Evan Carlson '14</span>
              <span className="small"><br/>	Southwestern University
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="U.S. Naval Academy" src="/img/alumni/navy.gif" />
              <p><span className="bigger-text">Adam Coberly '14</span>
              <span className="small"><br/>	U.S. Naval Academy
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Widener University" src="/img/alumni/widener.gif" />
              <p><span className="bigger-text">Connor Ganley '14</span>
              <span className="small"><br/>	Widener University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio State University" src="/img/alumni/ohiostate.gif" />
              <p><span className="bigger-text">Bo Lori '14</span>
              <span className="small"><br/>	Ohio State University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Muskingum University" src="/img/alumni/muskingum.gif" />
              <p><span className="bigger-text">Ohlen Rudy '14</span>
              <span className="small"><br/>	Muskingum University
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Quinnipiac University" src="/img/alumni/quinnipiac.gif" />
              <p><span className="bigger-text">Zack Vehar '14</span>
              <span className="small"><br/>	Quinnipiac University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="US Merchant Marine Academy" src="/img/alumni/usmerchmarines.gif" />
              <p><span className="bigger-text">Ben Wiese '14</span>
              <span className="small"><br/>	US Merchant Marine Academy
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Boston University" src="/img/alumni/boston.gif" />
              <p><span className="bigger-text">Tyler Zickel '14</span>
              <span className="small"><br/>	Boston University
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Siena College" src="/img/alumni/siena.gif" />
              <p><span className="bigger-text">Patrick O'Loughlin '13</span>
              <span className="small"><br/>	Siena College
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Michigan" src="/img/alumni/michigan.gif" />
              <p><span className="bigger-text">Cooper Charlton '11</span>
              <span className="small"><br/>	University of Michigan
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Massachusetts" src="/img/alumni/umass.gif" />
              <p><span className="bigger-text">Robby Lynott '11</span>
              <span className="small"><br/>	University of Massachusetts
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Centre College" src="/img/alumni/centre.gif" />
              <p><span className="bigger-text">Bryce McCoy '11</span>
              <span className="small"><br/>	Centre College
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Carthage College" src="/img/alumni/carthage.gif" />
              <p><span className="bigger-text">Ryan Drought '09</span>
              <span className="small"><br/>	Carthage College
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="VMI" src="/img/alumni/vmi.gif" />
              <p><span className="bigger-text">Evan Frankovits '09</span>
              <span className="small"><br/>	VMI
                <br />Midfield/FO</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="St. Vincent College" src="/img/alumni/stvincent.gif" />
              <p><span className="bigger-text">Chris McCarthy '09</span>
              <span className="small"><br/>	St. Vincent College
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Hobart College" src="/img/alumni/hobart.gif" />
              <p><span className="bigger-text">Jake McHenry '09</span>
              <span className="small"><br/>	Hobart College
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Denison University" src="/img/alumni/denison.gif" />
              <p><span className="bigger-text">Connor McMahon '09</span>
              <span className="small"><br/>	Denison University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Massachusetts" src="/img/alumni/umass.gif" />
              <img alt="Ohio Machine" src="/img/alumni/ohio-machine.gif" />
              <p><span className="bigger-text">Bobby Tyler '09</span>
              <span className="small"><br/>	U Mass, Ohio Machine
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Syracuse University" src="/img/alumni/syracuse.gif" />
              <p><span className="bigger-text">Griffin Vehar '09</span>
              <span className="small"><br/>	Syracuse University
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Bellarmine University" src="/img/alumni/bellarmine.gif" />
              <p><span className="bigger-text">Drew Larson '08</span>
              <span className="small"><br/>	Bellarmine University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Navy Prep" src="/img/alumni/navyprep.gif" />
              <p><span className="bigger-text">Billy Paster '08</span>
              <span className="small"><br/>	Navy Prep, West Virginia Club
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University at Albany" src="/img/alumni/albany.gif" />
              <p><span className="bigger-text">Matt Novosel '07</span>
              <span className="small"><br/>	University at Albany
                <br />D/LSM</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Bellarmine University" src="/img/alumni/bellarmine.gif" />
              <p><span className="bigger-text">Drew Soeder '07</span>
              <span className="small"><br/>	Bellarmine University
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Tri-State University" src="/img/alumni/tristate.gif" />
              <img alt="Kent State University" src="/img/alumni/kentstate.gif" />
              <p><span className="bigger-text">Logan Parsons '07</span>
              <span className="small"><br/>	Tri-State University, Kent State University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Adrian College" src="/img/alumni/adrian.gif" />
              <p><span className="bigger-text">Shane Hoffman '07</span>
              <span className="small"><br/>	Adrian College
                <br />Midfield/FO</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="VMI" src="/img/alumni/vmi.gif" />
              <p><span className="bigger-text">Sean McCoy '06</span>
              <span className="small"><br/>	VMI
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="UMBC" src="/img/alumni/umbc.gif" />
              <p><span className="bigger-text">Tim Egan '06</span>
              <span className="small"><br/>	UMBC
                <br />Attack / Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Lehigh University" src="/img/alumni/lehigh.gif" />
              <p><span className="bigger-text">Chris Linko '06</span>
              <span className="small"><br/>	Lehigh University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Herkimer County CC" src="/img/alumni/herkimer.gif" />
              <img alt="Ohio State University" src="/img/alumni/ohiostate.gif" />
              <p><span className="bigger-text">Justin Hayes '06</span>
              <span className="small"><br/>	Herkimer County CC, Ohio State University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio Wesleyan University" src="/img/alumni/ohiowesleyan.gif" />
              <p><span className="bigger-text">Ryan Campbell '04</span>
              <span className="small"><br/>	Ohio Wesleyan University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Mercyhurst College" src="/img/alumni/mercyhurst.gif" />
              <p><span className="bigger-text">Chris Davis '04</span>
              <span className="small"><br/>	Mercyhurst College
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio Wesleyan University" src="/img/alumni/ohiowesleyan.gif" />
              <p><span className="bigger-text">Mike Eizenberg '04</span>
              <span className="small"><br/>	Ohio Wesleyan University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Denison University" src="/img/alumni/denison.gif" />
              <p><span className="bigger-text">Rickey Mackessy '04</span>
              <span className="small"><br/>	Denison University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="College of Wooster" src="/img/alumni/wooster.gif" />
              <p><span className="bigger-text">Ian Parsons '04</span>
              <span className="small"><br/>	College of Wooster
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Miami University" src="/img/alumni/miami.gif" />
              <p><span className="bigger-text">Adam Popilowski '04</span>
              <span className="small"><br/>	Miami University
                <br />Midfield / Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="College of Wooster" src="/img/alumni/wooster.gif" />
              <p><span className="bigger-text">David Loudenslager '03</span>
              <span className="small"><br/>	College of Wooster
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="University of Notre Dame" src="/img/alumni/notredame.gif" />
              <p><span className="bigger-text">Patrick O'Toole '03</span>
              <span className="small"><br/>	University of Notre Dame
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="College of Wooster" src="/img/alumni/wooster.gif" />
              <p><span className="bigger-text">Peter Park '02</span>
              <span className="small"><br/>	College of Wooster
                <br />Midfield/FO</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio State University" src="/img/alumni/ohiostate.gif" />
              <p><span className="bigger-text">Josh Wittenberg '02</span>
              <span className="small"><br/>	Ohio State University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Wingate University" src="/img/alumni/wingate.gif" />
              <p><span className="bigger-text">Andy Tyler '01</span>
              <span className="small"><br/>	Wingate University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Manhattan College" src="/img/alumni/manhattan.gif" />
              <p><span className="bigger-text">Brett Warmington '01</span>
              <span className="small"><br/>	Manhattan College
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <div className="hof">
                <img alt="Butler University" src="/img/alumni/butler.gif" />
                <p><span className="bigger-text">Matt Eizenberg '00</span>
                <span className="small"><br/> Hudson Hall of Fame</span>
                <span className="small"><br/>	Butler University
                  <br />Midfield</span></p>
              </div>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Wittenberg University" src="/img/alumni/wittenberg.gif" />
              <p><span className="bigger-text">Jim Kelley '00</span>
              <span className="small"><br/>	Wittenberg University
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Butler University" src="/img/alumni/butler.gif" />
              <p><span className="bigger-text">Lawrence O'Toole '00</span>
              <span className="small"><br/>	Butler University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Wittenberg University" src="/img/alumni/wittenberg.gif" />
              <p><span className="bigger-text">Hans Rydquist '00</span>
              <span className="small"><br/>	Wittenberg University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="VMI" src="/img/alumni/vmi.gif" />
              <p><span className="bigger-text">Paul Green '99</span>
              <span className="small"><br/>	VMI
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio Wesleyan University" src="/img/alumni/ohiowesleyan.gif" />
              <p><span className="bigger-text">Pat O'Grady '99</span>
              <span className="small"><br/>	Ohio Wesleyan University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Wheeling Jesuit University" src="/img/alumni/wheelingjesuit.gif" />
              <p><span className="bigger-text">Justin Snyder '99</span>
              <span className="small"><br/>	Wheeling Jesuit University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio Wesleyan University" src="/img/alumni/ohiowesleyan.gif" />
              <p><span className="bigger-text">Steve Blaine '98</span>
              <span className="small"><br/>	Ohio Wesleyan University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="College of Wooster" src="/img/alumni/wooster.gif" />
              <p><span className="bigger-text">Ryan McNally '98</span>
              <span className="small"><br/>	College of Wooster
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Pfeiffer College" src="/img/alumni/pfeiffer.gif" />
              <p><span className="bigger-text">Kevin Turner '98</span>
                <span className="small"><br/>	Pfeiffer College
                <br />Defense</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio State University" src="/img/alumni/ohiostate.gif" />
              <p><span className="bigger-text">Jason Friedman '97</span>
              <span className="small"><br/>	Ohio State University
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Wittenberg University" src="/img/alumni/wittenberg.gif" />
              <p><span className="bigger-text">Chris Gruber '97</span>
              <span className="small"><br/>	Wittenberg University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="College of Wooster" src="/img/alumni/wooster.gif" />
              <p><span className="bigger-text">John Park '97</span>
              <span className="small"><br/>	College of Wooster
                <br />Goalie</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Ohio State University" src="/img/alumni/ohiostate.gif" />
              <p><span className="bigger-text">John Watterson '97</span>
              <span className="small"><br/>	Ohio State University
                <br />Attack</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Washington &amp; Lee University" src="/img/alumni/washingtonlee.gif" />
              <p><span className="bigger-text">Andy Thomas '96</span>
              <span className="small"><br/>	Washington &amp; Lee University
                <br />Midfield</span></p>
            </div>
            <div className="mb-4 col-lg-3 col-md-4 col-sm-6">
              <img alt="Washington &amp; Lee University" src="/img/alumni/washingtonlee.gif" />
              <p><span className="bigger-text">Bob Tullis '96</span>
              <span className="small"><br/>	Washington &amp; Lee University
                <br />Midfield</span></p>
            </div>
        </div>
      </div>
    </div>
  )
};
