import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from '../../utils/config';
import axios from 'axios';
import ParentInfoForm from '../../Components/Forms/ParentInfoForm';
import ParentInfoForgot from '../../Components/Forms/ParentInfoForgot';
import './ParentInfo.css'

export const ParentInfo = (props) => {
  const [userAuthed, setUserAuth] = useState();
  const [localState, setLocalState] = useState({
    unlocked: false,
    forgot: false,
    emailSent: false,
  });

  useEffect(() => {
    checkUserAuth();
  })

  async function checkUserAuth(userPwd) {
    console.log('checkUserAuth');
    const route = userPwd ?
      `${API_BASE_URL}/api/userauth/${userPwd}` :
      `${API_BASE_URL}/api/userauth`;
    const res = await axios.get(route);
    setUserAuth(res.data.authed);
    if (res.data.authed) {
      setLocalState({ ...localState, unlocked: true })
    }
    console.log('authed: ' + res.data.authed);
    return res.data.authed;
  }

  async function submitForgot(name, email) {
    const route = `${API_BASE_URL}/api/email-forgot/`;
    const res = await axios.post(route, { name, email });
    if (res) {
      setLocalState({ ...localState, forgot: false, emailSent: true })
    }
  }

  function handleForgotClick() {
    setLocalState({ ...localState, forgot: true, emailSent: false });
  }

  function handlePasswordClick() {
    setLocalState({ ...localState, forgot: false, emailSent: false });
  }

  function renderForgotInput() {
    return (
    <div className="formcontainer">
      <ParentInfoForgot onSubmit={submitForgot} />
      <div className="fieldGroup">
        <a className="forgotText" href="#" onClick={handlePasswordClick}>Enter team password</a>
      </div>
    </div>);
  }

  function renderPasswordInput() {
    return (
    <div className="formcontainer">
      {localState.emailSent && <div className="emailSent">An email has been sent to the coaches. They will communicate the password to you shortly.</div>}
      <ParentInfoForm onSubmit={checkUserAuth} submitText='Go' />
      <div className="fieldGroup">
        <a className="forgotText" href="#" onClick={handleForgotClick}>Forgot team password</a>
      </div>
    </div>);
  }

  return (
    <div className="bodyContainer">
      <div className="container-fluid pagebody d-flex">
        <div className="container text-center">
          <h1 className="cover-heading">Parent Information</h1>
          {!localState.unlocked && !localState.forgot && renderPasswordInput()}
          {!localState.unlocked && localState.forgot && renderForgotInput()}
          {localState.unlocked && <div className="container-fluid pagebody text-center">
            <h2>VARSITY BOYS SIGN UPS 2021</h2>
            <div><a target="_blank" href="https://www.signupgenius.com/go/10C0A4DA9AB28A4FF2-gatorade3">Varsity Gatorade Donations</a></div>
            <div>&nbsp;</div>
            <h2>JV BOYS SIGN UPS 2021</h2>
            <div><a target="_blank" href="https://www.signupgenius.com/go/10C0A4DA9AB28A4FF2-jvgame1">JV Game Filming</a></div>
            <div><a target="_blank" href="https://www.signupgenius.com/go/10C0A4DA9AB28A4FF2-gatorade2">JV Gatorade Donations</a></div>
          </div>}
        </div>
      </div>
    </div>
  )
};
