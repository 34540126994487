import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import './forms.css'

export const ParentInfoForm = (props) => {
  const [isFailedAuth, setFailedAuth] = useState(false);
  const schema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter a password")
  });

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        const authSuccessful = await props.onSubmit(values.password);
        setFailedAuth(!authSuccessful);
        setSubmitting(false);
      }}
    >
      {({ values, touched, errors, isSubmitting, handleChange, handleSubmit }) => {
      const onChange = (e) => {
        setFailedAuth(false);
        handleChange(e);
      }
      return (
        <form onSubmit={handleSubmit}>
          <div className="formcontainer">
            <div className="fieldGroup">
              <label htmlFor="password">Enter team password:</label>
              <input
                type="password"
                name="password"
                onChange={onChange}
                value={values.password}
                placeholder="enter team password"
                className={errors.password && touched.password && 'fld-err'}
              />
              <button type="submit" disabled={isSubmitting}>{props.submitText || 'Submit'}</button>
            </div>
            <ErrorMessage className='err-msg' name="password" component="div" />
            {isFailedAuth && <div className='err-msg'>Incorrect Password</div>}
          </div>
          {props.children}
        </form>
      );
      }}
    </Formik>);
};

export default ParentInfoForm;