import React from "react";
import Container from "react-bootstrap/Container";
import { Banner } from '../Banner/Banner'

export const Fundraising = (props) => {
  return (
    <div className="bodyContainer">
      <Banner BannerText="Fundraising" />
      <Container>
      <div className="container marketing">
        <div className="row mb-4">
          <p>Looking for an opportunity to contribute to the success of the Explorers Lacrosse legacy? Here's your chance!</p>
        </div>
        {/* <div className="row mb-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body d-flex flex-column flex-grow-1">
                <h5 className="mb-1 bio-name">Raffle</h5>
                <span className="card-text mb-auto">
                  <p>asd</p>
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body d-flex flex-column flex-grow-1">
                <h5 className="mb-1 bio-name">Meet the Team</h5>
                <span className="card-text mb-auto">
                  <p>Saturday 12/17/22</p>
                  <p>Bring the family and friends to meet the Hudson High School Boy’s Lacrosse Team that won the Regional
Championship in ’22 with their sites set on a state title in 2023.</p>
                  <p>Join us for a fun evening of food, drinks, auction items and mingling with the players, coaches and their families at
EDGE SPORTS ACADEMY 9550 Ravenna Rd in Twinsburg, Ohio</p>
                  <p><a href="/docs/meet_the_team.pdf" target="_blank">more details</a></p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body d-flex flex-column flex-grow-1">
                <h5 className="mb-1 bio-name">Leaders, Legends, Lacrosse</h5>
                <p>Monday 12/12/22 6-9pm</p>
                <p>Please join us on December 12th from 6-9 at Comida Restaurant in Hudson, Ohio for a great night to benefit Hudson High School Boys Lacrosse!</p>
                <p>Enjoy open bar, food, live/silent auction, meet and greet as well as hearing from our special guests that are truly LEADERS and LEGENDS in the community and sports world!</p>
                  <p><a href="/docs/lll.pdf" target="_blank">more details</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Container>
    </div>
  )
};