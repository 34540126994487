import React from "react";
import Container from "react-bootstrap/Container";
import { Banner } from '../Banner/Banner'

export const Staff = (props) => {
  return (
    <div className="bodyContainer">
      <Banner BannerText="Coaching Staff" />
      <Container>
      <div className="container marketing">
        <div className="row mb-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="align-self-center m-3">
                  <img className="card-img flex-auto d-none d-md-block" alt="Coach Schwind" src="/img/schwind.jpg" />
              </div>
              <div className="card-body d-flex flex-column flex-grow-1">
                <h3 className="mb-2 text-dark">Brandon Schwind</h3>
                <span className="card-text mb-auto">
                  <p>Coach Schwind was named Hudson High School’s head boy’s lacrosse coach in August 2018.  In 2023 he enters his fifth season at the helm of the Explorers.</p>
                  <p>Schwind has coached the game at every level.  His first coaching opportunity began as a graduate assistant at NCAA Div. III Fontbonne University in Saint Louis, MO.  At Fontbonne, he coached the defense and served as the head recruitment coordinator.  In 2010, Schwind left Saint Louis and moved to Michigan and accepted his first head coaching position at a local high school.</p>
                  <p>In Michigan, Schwind enjoyed coaching stints at Haslett High School, Michigan State University and Okemos High School.  In eight total seasons as a head coach, Schwind’s teams have accumulated a 116-41 record (73%), won six league championships and notably received eight college and high school All American honors.  The 2022 season with will be his 12th year as a head coach.</p>
                  <p>As a player, Schwind got his start playing high school lacrosse under Coach Ted Wolford at Upper Arlington High School in Columbus, Ohio.  From high school, he continued his lacrosse career at NCAA Div. III Wittenberg University in Springfield, OH.  At Wittenberg, Schwind was a four-year starter, a 3x All NCAC Selection, Team Defensive MVP and served as team captain his senior year.</p>
                </span>
              </div>
            </div>
          </div>
        </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Patrick O'Loughlin</h5>
                <span className="card-text">
                  <p>Varsity Assistant</p>
                </span>
              </div>
            </div>
          </div>
        <div className="row mb-4">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Whit Stopak</h5>
                <span className="card-text">
                  <p>Varsity Assistant</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Cam Logan</h5>
                <span className="card-text">
                  <p>Varsity Assistant</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Anthony Ameo</h5>
                <span className="card-text">
                  <p>Varsity Assistant</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Aaron Polak</h5>
                <span className="card-text">
                  <p>JV Head Coach</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Merrick Widdoes</h5>
                <span className="card-text">
                  <p>JV Assistant</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow h-md-250 d-flex">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1 bio-name">Matt Wysmierski</h5>
                <span className="card-text">
                  <p>JV Assistant</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Container>
    </div>
  )
};