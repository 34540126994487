import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Navigation } from './Components/Navigation/Navigation';
import { Footer } from './Components/Footer/Footer';
import { Home } from './Components/Pages/Home';
import { Staff } from './Components/Pages/Staff';
import { Fundraising } from './Components/Pages/Fundraising';
import { Alumni } from './Components/Pages/Alumni';
import { Directions } from './Components/Pages/Directions';
import { ParentInfo } from './Components/Pages/ParentInfo';
import { PracticeSchedule } from './Components/Pages/PracticeSchedule';
import { AlumniForm } from './Components/Pages/AlumniForm';
import { NotFound } from './Components/Pages/NotFound';
import Roster from './Components/Pages/Roster';
import Schedule from './Components/Pages/Schedule';
import { ENV } from './utils/config';
import ReactGA from 'react-ga';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  console.log(ENV);
  if (ENV !== 'development') {
    ReactGA.initialize('UA-133683799-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return (
    <BrowserRouter>
      <div className='header'>
        <Navigation />
      </div>
      <div className='body'>
        <Switch>
          <Route exact path="/schedule">
            <Redirect to="/schedule/2023" />
          </Route>
          <Route exact path="/roster">
            <Redirect to="/roster/2023" />
          </Route>
          <Route path="/schedule/:szn" component={Schedule} />
          <Route path="/roster/:szn" component={Roster} />
          <Route path="/staff" component={Staff} />
          <Route path="/contribute" component={Fundraising} />
          <Route path="/alumni" component={Alumni} />
          <Route path="/alumni-registry" component={AlumniForm} />
          <Route path="/directions" component={Directions} />
          <Route path="/parents" component={ParentInfo} />
          <Route path="/practices" component={PracticeSchedule} />
          <Route exact path="/" component={Home} />
          <Route exact path="/notfound" component={NotFound} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
