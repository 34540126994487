import React from "react";
import { Banner } from '../Banner/Banner'

export const Directions = (props) => {
  return (
    <div className="bodyContainer">
      <Banner BannerText="Directions" />
      <div className="container marketing">
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow ">
              <div className="card-body flex-grow-1 align-self-center">
                <h5 className="mb-1">Hudson Memorial Field</h5>
                <span className="card-text">
                  <p>Hudson High School<br />2500 Hudson Aurora Rd<br />Hudson, OH 44236</p>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card flex-md-row mb-4 box-shadow">
              <div className="card-body flex-grow-1 align-self-center">
                  <a href="https://www.google.com/maps/place/Hudson+High+School/@41.2580218,-81.4220301,14z/data=!4m8!1m2!3m1!2sHudson+High+School!3m4!1s0x8831223b2dbbd839:0x38bfbdc10ba45954!8m2!3d41.2568314!4d-81.4139056" target="_blank" rel="noopener noreferrer">
                    <img className="card-img" alt="Map to HHS" src="/img/map.png" />
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};