import _ from 'lodash';
import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import { Banner } from '../Banner/Banner'
import { Loader } from '../Loader/Loader';
import { API_BASE_URL } from '../../utils/config';
import axios from 'axios';

export class PracticeSchedule extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { jvSchedule: [], varsitySchedule: [], loading: true, loaded: false }
  }

  componentWillMount() {
    this.loadPractices();
  }

  async loadPractices() {
    const jvSchedule = await axios.get(`${API_BASE_URL}/api/practice-schedule/jv`);
    const varsitySchedule = await axios.get(`${API_BASE_URL}/api/practice-schedule/varsity`);

    this.setState({
      jvSchedule: jvSchedule.data,
      varsitySchedule: varsitySchedule.data,
      loading: false,
      loaded: true })
  }

  renderScheduleRow(item, index, teamName) {
    const date = `${moment(item.Date).format('ddd')} ${moment(item.Date).format("MMM Do")}`;
    const what = item.Type.toLowerCase() === 'game' && item.Classification ?
      `${item.Classification} Game` :
      item.Type;
    const where = item.LocationUrl ?
      <a target="_blank" href={item.LocationUrl}>{item.Location}</a> :
      item.Location;
    return (
      <tr key={`scheduleItem-${teamName}-${index}`}>
        <td className="nowrap">
          <div>{date}</div>
          <div>{item.Time}</div>
        </td>
        <td>
          <div>
            {`${what} at `}
            {where}
          </div>
          <div>{item.Notes}</div>
        </td>
      </tr>
    )
  }

  renderTeamSchedule(schedule, teamName) {
    return (
    <div className="tableWrapper practice">
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th className="col-sm" scope="col">When</th>
            <th className="col-lg" scope="col">What / Where</th>
          </tr>
        </thead>
        <tbody>
          {
            schedule.map((item, index) => (
              this.renderScheduleRow(item, index, teamName)
            ))
          }
        </tbody>
      </table>
    </div>
    )
  }

  render() {
    const { jvSchedule, varsitySchedule, loading } = this.state;
    return (
      <div className="bodyContainer">
        <Banner BannerText="Practice Schedule" />
        <div className="container">
          <div className="row">
            { loading && 
            <Loader />
            }
            {!loading && varsitySchedule.length > 0 && <div className="col-lg-6 col-md-12 mb-4">
              <h2>Varsity Practice Schedule</h2>
              {this.renderTeamSchedule(varsitySchedule)}
            </div>}
            {!loading && jvSchedule.length > 0 && <div className="col-lg-6 col-md-12">
              <h2>JV Practice Schedule</h2>
              {this.renderTeamSchedule(jvSchedule)}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default PracticeSchedule;
