import React from "react";
import Delayed from '../Delayed/Delayed';
import { Timeline } from 'react-twitter-widgets';
import './Home.css';

export const Home = (props) => {
  return (
    <div className="homeContainer">
      <img className="bg-img" src="/img/photos/memorial2.png" alt="Hudson Lacrosse Memorial Field" />
      <div className="outer-container">
        <div className="container">
          <div className="row fullrow hero-row1">
            <div className="homeHero">
              <div className="heroLogo">
                <img src="/img/hhs_lax_logo_2020_350.png" alt="Hudson Lacrosse Logo" />
              </div>
              <div className="heroMessagesContainer">
                <div className="heroMessages">
                  <Delayed waitBeforeShow={0}>
                    <div className="msg animated fadeInLeft">Team</div>
                  </Delayed>
                  <Delayed waitBeforeShow={0}>
                    <div className="msg animated fadeInLeft">> Teammate</div>
                  </Delayed>
                  <Delayed waitBeforeShow={0}>
                    <div className="msg animated fadeInLeft">> Self</div>
                  </Delayed>
                  <Delayed waitBeforeShow={0}>
                    <div className="msg2 animated fadeInLeft"># BE ELITE</div>
                  </Delayed>
                </div>
              </div>
            </div>
          </div>
          <div className="row fullrow centeredrow hero-row2">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <Timeline
                dataSource={{
                  sourceType: 'profile',
                  screenName: 'HudsonHSLax'
                }}
                options={{
                  username: 'HudsonHSLax',
                  height: '400'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};