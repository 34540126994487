import React from "react";
import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom";
import './SeasonNav.css';

export const SeasonNav = (props) => {
  return (
    <div className="seasonLinks">
      <div className="container">
      <Nav>
        { props.seasons.map(szn => (
          <Nav.Item>
            <NavLink activeClassName='active' to={`${props.baseRoute}/${szn.szn}`}>{szn.szn}</NavLink>
          </Nav.Item>
        ))}
      </Nav>
      </div>
    </div>
  )
};